import React from 'react'
import NavigationMenu from './NavigationMenu'
import Footer from './Footer'

function Layout(props: any) {

    return (
        <>
            <NavigationMenu />
            {props.children}
            <Footer />
        </>
    )
}

export default Layout
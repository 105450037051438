import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import classes from './Footer.module.css';
import { FacebookIcon, LinkedInIcon, YoutubeIcon, InstagramIcon } from '../../ui/icons';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

function Footer() {
    const { locale } = useRouter();
    const intl = useIntl();

    const footerDef = intl.formatMessage({ id: "footer-P-1" })
    const platform = intl.formatMessage({ id: "footer-H-2" })
    const platform_con = intl.formatMessage({ id: "footer-P-2" })
    const mission = intl.formatMessage({ id: "footer-H-3" })
    const mission_con = intl.formatMessage({ id: "footer-P-3" })
    const follow_us = intl.formatMessage({ id: "footer-H-4" })
    const copyright = intl.formatMessage({ id: "copy.R" })
    const privacy = intl.formatMessage({ id: "privacy" })
    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes[locale === 'ar' ? 'footer_menu_ar' : 'footer_menu']}>
                    <div className={classes.footer_section}>
                        <Image src="/logo_white.svg" width={200} height={42} alt="logo" />
                        <p style={{ paddingLeft: locale === 'ar' ? '50px' : '' }}>
                            {footerDef}
                        </p>
                    </div>
                    <div className={classes.footer_section}>
                        <h4 className={classes.headText}>
                            {platform}
                        </h4>
                        <p style={{ paddingLeft: locale === 'ar' ? '20px' : '' }}>
                            {platform_con}
                        </p>
                    </div>
                    <div className={classes.footer_section}>
                        <h4 className={classes.headText}>
                            {mission}
                        </h4>
                        <p>
                            {mission_con}
                        </p>
                    </div>
                    <div className={classes.footer_section}>
                        <h4
                            className={
                                `${classes.headText} ${classes.followus_text} ${classes[locale === 'ar' ? 'followus_text_ar' : '']}`
                            }
                        >
                            {follow_us}
                        </h4>
                        <div
                            className={classes.follow}
                            style={{ display: locale === 'ar' ? 'flex' : '', justifyContent: locale === 'ar' ? 'end' : '' }}
                        >
                            <div className={classes.btn_container}>
                                <Link href="https://www.facebook.com/sketshare/" passHref={true}>
                                    <a target="_blank" rel="noopener noreferrer" className='link-item'>
                                        <div className={classes.btn}>
                                            <FacebookIcon />
                                        </div>
                                    </a>
                                </Link>
                                <Link href="https://youtube.com/channel/UCKwwr1iouNeYKxwQWT0eNMA" passHref={true}>
                                    <a target="_blank" rel="noopener noreferrer" className='link-item'>
                                        <div className={classes.btn}>
                                            <YoutubeIcon />
                                        </div>
                                    </a>
                                </Link>
                                <Link href="https://www.linkedin.com/company/sketshare-ltd" passHref={true}>
                                    <a target="_blank" rel="noopener noreferrer" className='link-item'>
                                        <div className={classes.btn}>
                                            <LinkedInIcon />
                                        </div>
                                    </a>
                                </Link>
                                <Link href="https://www.instagram.com/sketshare/" passHref={true}>
                                    <a target="_blank" rel="noopener noreferrer" className='link-item'>
                                        <div className={classes.btn}>
                                            <InstagramIcon />
                                        </div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.footerBottom}>
                    <div className={classes.rowGrid}>
                        <div>
                            <div className={classes.copyrightText}>{copyright}</div>
                        </div>
                        <div>
                            <div className={classes.privacyTerms}>
                                {/* <Link href={"/"}>Terms Of Services</Link> |  */}
                                <Link href={"/privacypolicy"}>{privacy}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer